<template>
  <component
    :is="componentName"
    v-bind="componentProps"
    class="border border-gray-300 rounded-br-3xl py-12 px-4 text-center text-sm bg-white"
    :class="{ 'hover:bg-gray-100 duration-100': to || hover }"
  >
    <span v-if="iconName" class="bg-primary text-white size-12 rounded-full flex mx-auto mb-8">
      <Icon :name="iconName" :size="size * 2.3" :weight="500" class="m-auto" :class="iconClass" />
    </span>
    <FrontpageHeadline level="5" class="mb-4 block" tag="span">{{ headline }}</FrontpageHeadline>
    <span class="block">
      <slot name="default"></slot>
    </span>
  </component>
</template>

<script setup>
import { NuxtLink } from "#components";

const props = defineProps({
  iconName: {
    type: String,
    default: "",
  },

  iconClass: {
    type: String,
    default: "",
  },

  headline: {
    type: String,
    default: "",
  },

  to: {
    type: String,
    default: "",
  },

  hover: {
    type: Boolean,
    default: false,
  },

  size: {
    type: [Number, String],
    default: 12,
  },
});

const componentName = props.to ? NuxtLink : "div";
const componentProps = props.to ? { to: props.to } : {};
</script>
